<template>
  <div class="app-mall-order-detail container">
    <div class="content ex padding-20-0">
      <div class="h c">
        <el-button icon="el-icon-back" @click="handleBack"></el-button>
        <div class="flex fs-large padding-0-10">
          订单详情
          <span class="fc-e" v-if="form && form.isPrepare">【预售订单】</span>
        </div>
        <template v-if="form">
          <el-button type="primary" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_SEND']" @click="doOutbound" v-if="(form.status === 'pass' || form.status === 'unsend' || form.status === 'sendPart')">发货</el-button>
          <el-button type="success" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_APPEAR']" @click="doReport" v-if="(form.status === 'pass' || form.status === 'unsend' || form.status === 'sended' || form.status === 'sendPart') && !form.sendNotice" :loading="reporting">上报</el-button>
          <el-button type="danger" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_CLOSE']" @click="doClose" v-if="form.status === 'pass' || form.status === 'unsend' || form.status === 'sendPart'" :loading="closing">关闭订单</el-button>
        </template>
      </div>
      <el-form :model="form" label-width="62px" label-position="right" label-suffix=":" v-if="form">
        <div class="h s">
          <div style="width: 480px; margin-right: 50px;">
            <div class="item-title">基本信息</div>
            <div class="item-content h sb">
              <div>
                <form-info-item label="订单编号">{{form.formCode}}</form-info-item>
                <form-info-item label="ERP编号" v-if="form.erpCode">{{form.erpCode}}</form-info-item>
                <form-info-item label="合同号" v-if="form.contractNo">{{form.contractNo}}</form-info-item>
                <form-info-item label="单据日期">{{new Date(form.purchaseTime).format("yyyy/MM/dd")}}</form-info-item>
              </div>
              <div>
                <form-info-item label="订单状态">
                  <dot :type="status[form.status].type" same>{{status[form.status].label}}</dot>
                </form-info-item>
                <form-info-item label="上报情况">
                  <dot type="success" same v-if="form.sendNotice">已上报</dot>
                  <dot type="disabled" same v-else>未上报</dot>
                </form-info-item>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="item-title">收货信息</div>
            <div class="item-content">
              <form-info-item label="收货人">{{form.customerName}}（{{form.customerPhone}}）</form-info-item>
              <form-info-item label="收货地址">{{form.provinceName}}{{form.cityName}}{{form.districtName}}{{form.customerAddress}}</form-info-item>
            </div>
          </div>
        </div>

        <div class="item-title">商品清单</div>
        <div class="item-content">
          <el-table :data="form.items" empty-text="当前采购单没有任何商品">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column label="商品" min-width="240">
              <div style="line-height: 1.5" slot-scope="scope">
                <el-tag :type="goodsItemTypes[scope.row.itemType || 'sale'].type" size="mini">{{goodsItemTypes[scope.row.itemType || 'sale'].label}}</el-tag>
                <div>{{scope.row.goodsName}}</div>
                <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
              </div>
            </el-table-column>
            <el-table-column label="现场提货" width="70" align="center">
              <template slot-scope="scope">
                <i class="fa fa-check" v-if="scope.row.sendType === 0"></i>
              </template>
            </el-table-column>
            <el-table-column label="交货日期" width="100" align="center">
              <template slot-scope="scope">{{scope.row.deliveryDate?new Date(scope.row.deliveryDate).format('yyyy/MM/dd'):''}}</template>
            </el-table-column>
            <el-table-column label="单价" width="130" align="right">
              <div style="line-height: 1.5" slot-scope="scope">
                <b>{{$price(scope.row.realPrice)}}</b>
                <div class="fc-g through" v-if="scope.row.price > scope.row.realPrice">{{$price(scope.row.price)}}</div>
              </div>
            </el-table-column>
            <el-table-column label="数量" align="right" width="120">
              <template slot-scope="scope">
                <div class="h c r">
                  <span class="fc-g">销售:&nbsp;</span>
                  {{scope.row.quantity}}
                </div>
                <div class="h c r">
                  <span class="fc-g">已发货:&nbsp;</span>
                  {{scope.row.sendCount || 0}}
                </div>
                <div class="h c r">
                  <span class="fc-g">已取消:&nbsp;</span>
                  {{scope.row.cancelCount || 0}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="info" label="备注" min-width="150" show-overflow-tooltip />
            <el-table-column width="45" fixed="right" v-if="rowClosable">
              <div class="row-commands" slot-scope="scope">
                <el-button type="text" size="mini" @click="handleCloseItem(scope.row)" v-if="scope.row.quantity - scope.row.sendCount - scope.row.cancelCount > 0">关闭</el-button>
              </div>
            </el-table-column>
          </el-table>
          <div class="padding-10 h c r">
            <template v-if="form.discountAmount > 0">
              <form-info-item label="商品金额" style="margin-right: 50px;">
                <b>{{$price(form.totalMoney)}}</b>
              </form-info-item>
              <form-info-item label="优惠金额" style="margin-right: 50px;">
                <b class="fc-e">{{$price(form.discountAmount)}}</b>
              </form-info-item>
            </template>
            <form-info-item label="返利抵扣" style="margin-right: 50px;" v-if="form.useRebate && form.rebateAmount > 0">
              <b class="fc-e">{{$price(form.rebateAmount)}}</b>
            </form-info-item>
            <form-info-item label="订单金额" content-style="padding-right: 10px;">
              <b>{{$price(form.realTotalMoney || 0)}}</b>
            </form-info-item>
          </div>
        </div>

        <div class="item-title">付款信息</div>
        <div class="item-content">
          <pays-info :target="form" mall style="margin-top: 20px;" />
        </div>

        <div class="item-title">其他信息</div>
        <div class="item-content">
          <form-info-item label="优惠券">{{form.couponRemark || "无"}}</form-info-item>
          <form-info-item label="订单来源">{{form.source || "无"}}</form-info-item>
          <form-info-item label="备注">{{form.info || "无"}}</form-info-item>
          <form-info-item label="附件">
            <file-uploader :entity-id="form.fileId" folder="retail/attachments" accept empty-text="没有上传附件" multiple readonly :max-count="9" :thumbnail-size="80" />
          </form-info-item>
        </div>
      </el-form>
    </div>

    <el-dialog :visible.sync="outbound.show" title="发货" append-to-body :close-on-click-modal="false" width="1000px">
      <el-form ref="outboundForm" :model="outbound.form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
        <el-form-item prop="warehouseId" label="发货库房">
          <quick-select v-model="outbound.form.warehouseId" filterable url="api/warehouse" auto-select-first-option placeholder="请选择发货库房" style="width: 240px;" />
        </el-form-item>
        <el-table :data="outbound.form.items" border>
          <el-table-column label="商品" min-width="240">
            <div style="line-height: 1.5" slot-scope="scope">
              <el-tag :type="goodsItemTypes[scope.row.itemType || 'sale'].type" size="mini">{{goodsItemTypes[scope.row.itemType || 'sale'].label}}</el-tag>
              <div>{{scope.row.goodsName}}</div>
              <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
            </div>
          </el-table-column>
          <el-table-column label="库存" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
              <div v-else-if="scope.row._stockInfo.error" class="h c">
                <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
              </div>
              <div v-else-if="scope.row._stockInfo.values" class="h c">
                <b>{{scope.row._stockInfo.values.warehouseCount}}</b>
                <el-popover placement="bottom" width="400" trigger="click">
                  <div class="h c">
                    <span>库存数量：{{scope.row._stockInfo.values.warehouseCount}}</span>
                    <el-divider direction="vertical" />
                    <span>待发货数量：{{scope.row._stockInfo.values.saleCount}}</span>
                    <el-divider direction="vertical" />
                    <span>在途数量：{{scope.row._stockInfo.values.purchaseCount}}</span>
                  </div>
                  <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="销售数量" align="center" width="90" />
          <el-table-column label="已发货数量" width="90" align="center">
            <template slot-scope="scope">{{scope.row.sendCount || 0}}</template>
          </el-table-column>
          <el-table-column label="已取消数量" width="90" align="center">
            <template slot-scope="scope">{{scope.row.cancelCount || 0}}</template>
          </el-table-column>
          <el-table-column label="本次发货数量" width="120" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.currentSendCount" :min="0" :max="scope.row.sendableCount" :step="1" :precision="0" size="mini" controls-position="right" @change="$checkNaN(scope.row, 'currentSendCount', 0)" style="width: 100%;" v-if="scope.row.sendableCount > 0" />
              <span class="fc-s" v-else>发送完成</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button type="text" @click="outbound.show = false">取消</el-button>
        <el-button type="primary" :loading="outbound.submiting" @click="doOutboundSubmit">确认发货</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import { get, close, send, sendNotice, closeItem } from "@/api/shopOrder";
import paysInfo from "../../order/pays-info";
import { getGoodsByShopId } from "@/api/warehouse";

export default {
  components: { paysInfo },
  data() {
    return {
      form: null,
      reporting: false,
      closing: false,
      outbound: {
        show: false,
        submiting: false,
        form: {
          warehouseId: null,
          items: []
        }
      },
      rules: {
        warehouseId: [
          { required: true, message: "请选择发货库房", trigger: "change" }
        ]
      },
      goodsItemTypes: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
        simple: { label: "出样", type: "info" }
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款"
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        closedPart: { key: "closedPart", label: "部分已关闭", type: "info" }
      }
    };
  },
  computed: {
    rowClosable() {
      return (
        this.form &&
        (this.form.status === "pass" ||
          this.form.status === "unsend" ||
          this.form.status === "sendPart" ||
          this.form.status === "closedPart")
      );
    }
  },
  methods: {
    checkPermission,
    handleBack() {
      this.outbound.warehouseId = null;
      window._mall_handleback = true;
      this.$router.back();
    },
    load() {
      get(this.$route.params.id).then(res => {
        this.form = res;
      });
    },
    loadStockInfo(g) {
      if (g && g.goodsId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        g._stockInfo.values = null;
        getGoodsByShopId(g.goodsId, this.form.shopId)
          .then(res => {
            g._stockInfo.values = res;
          })
          .catch(err => {
            g._stockInfo.error = "获取失败";
          })
          .finally(_ => {
            g._stockInfo.loading = false;
          });
      }
    },
    doOutbound() {
      this.outbound.show = true;
      this.outbound.form.items = this.form.items.map(o => {
        let _no = Object.assign({}, o, {
          currentSendCount: o.quantity - o.sendCount,
          sendableCount: o.quantity - o.sendCount,
          _stockInfo: {
            loading: false,
            error: false,
            values: null
          }
        });
        this.loadStockInfo(_no);
        return _no;
      });
    },
    doOutboundSubmit() {
      this.$refs.outboundForm &&
        this.$refs.outboundForm.validate().then(_ => {
          let items = [];
          (this.outbound.form.items || []).forEach(o => {
            if (
              typeof o.currentSendCount === "number" &&
              o.currentSendCount > 0
            ) {
              items.push({
                id: o.id,
                goodsId: o.goodsId,
                currentSendCount: o.currentSendCount
              });
            }
          });
          if (items && items.length) {
            this.outbound.submiting = true;
            send({
              id: this.form.id,
              items: items,
              warehouseId: this.outbound.form.warehouseId
            })
              .then(_ => {
                this.$notify({
                  title: "发货成功",
                  type: "success",
                  duration: 2500
                });
                this.outbound.show = false;
                this.load();
              })
              .finally(_ => {
                this.outbound.submiting = false;
              });
          } else {
            this.$alert("请设置要发货的商品以及发货数量。", "系统提示");
          }
        });
    },
    doReport() {
      this.$confirm(
        "上报过程可能耗费较长时间，请耐心等候。<br />确定要进行上报吗？",
        "系统提示",
        {
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      ).then(_ => {
        this.reporting = true;
        sendNotice({
          id: this.form.id
        })
          .then(res => {
            this.$message({
              type: "success",
              message: "上报成功!"
            });
            this.load();
          })
          .finally(_ => {
            this.reporting = false;
          });
      });
    },
    doClose() {
      this.$confirm(`您确定要关闭该销售订单吗？`, "操作确认", {
        type: "warning"
      }).then(_ => {
        this.closing = true;
        close(this.form.id)
          .then(_ => {
            this.$alert(
              `销售订单已经成功关闭，请手动登记退款信息。`,
              "关闭销售单成功"
            );
            this.load();
          })
          .finally(_ => {
            this.closing = false;
          });
      });
    },

    handleCloseItem(row) {
      if (
        this.rowClosable &&
        row &&
        row.quantity - row.sendCount - row.cancelCount > 0
      ) {
        this.$confirm("确定要关闭当前行吗？", "操作确认", {
          type: "warning"
        }).then(_ => {
          closeItem(row.id).then(res => {
            this.$notify({
              title: "关闭行成功",
              type: "success",
              duration: 2500
            });
            this.load();
          });
        });
      }
    }
  },
  activated() {
    this.load();
  }
};
</script>